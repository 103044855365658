/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type SiteMetaData = {
	siteTitle: string;
	siteDescription: string;
	siteAuthor: string;
};

function Seo({ description = "",keywords ="", lang = "en", meta = [], title }: SEOProps) {
	const { siteTitle, siteDescription, siteAuthor } =
		useStaticQuery<SiteMetaData>(
			graphql`
				query {
					site {
						siteMetadata {
							title
							description
							author
						}
					}
				}
			`
		);

	const metaDescription = description || siteDescription;
	const defaultTitle = siteTitle;
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ""}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					name: `keywords`,
					content: keywords,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:image`,
					content:"https://greenflamingobcn.com/static/cgr-logo-desktop_with_background.jpg"
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: siteAuthor || ``,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}
		/>
	);
}

interface SEOProps {
	lang?: string;
	description?: string;
	keywords?:string;
	meta?: Array<{ name: string; content: string }>;
	title: string;
}

export default Seo;
